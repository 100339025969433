import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async () => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");
  const color = searchParams.get("color");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    scales: ['x05', 'x1'],
    colors: {
      main: color,
    }
  }

  new Widget('#widget', options);
}

createWidget();
